import axios from 'axios'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

let baseURL = 'https://adminapi.yundongm.cn:24586/api/jsc';
if(process.env.NODE_ENV == "development"){
  baseURL = 'https://adminapi.yundongm.cn:24586/api/jsc';
} else if(process.env.NODE_ENV == "production"){
  baseURL = 'https://adminapi.yundongm.cn:24586/api/jsc';
}

// 创建axios实例
const service = axios.create({
  baseURL: baseURL,
  // 超时
  timeout: 600000
})
// request拦截器
service.interceptors.request.use(config => {

  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    return res.data
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    return Promise.reject(error)
  }
)

export default service
