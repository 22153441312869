import Vue from "vue";
import VueRouter from "vue-router";
// import layout from '@/components/layout/index.vue'

// import integratedData from '@/views/integratedData/index.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    // component: () => import('../views/index.vue')
    meta: {
      title: "首页",
    },
    component: () => import(/* webpackChunkName: "home" */ "@/views/index.vue"),
    redirect: "/integratedData",
    children: [
      {
        path: "integratedData",
        name: "IntegratedData",
        meta: {
          title: "健身锻炼综合数据",
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegratedData" */
            "@/views/integratedData/index.vue"
          ),
      },
      {
        path: "schoolSite",
        name: "SchoolSite",
        meta: {
          title: "校体开放",
        },
        component: () =>
          import(
            "@/views/school-site/index.vue"
          ),
      },
      {
        path: "matchActivity",
        name: "matchActivity",
        meta: {
          title: "赛事活动",
        },
        component: () =>
          import(
            /* webpackChunkName: "matchActivity" */
            "@/views/matchActivity/index.vue"
          ),
      },
      {
        path: "grassrootsSports",
        name: "grassrootsSports",
        meta: {
          title: "基层体育委员组织",
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegratedData" */
            "@/views/grassrootsSports/index.vue"
          ),
      },
      {
        path: "sportFacilities",
        name: "SportFacilities",
        meta: {
          title: "体育场地设施",
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegratedData" */
            "@/views/sportFacilities/index.vue"
          ),
      },
      {
        path: "sportsSociety",
        name: "sportsSociety",
        meta: {
          title: "体育社会组织",
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegratedData" */
            "@/views/sportsSociety/index.vue"
          ),
      },
      {
        path: "socialInstructor",
        name: "socialInstructor",
        meta: {
          title: "社会指导员",
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegratedData" */
            "@/views/socialInstructor/index.vue"
          ),
      },
      {
        path: "sportsBank",
        name: "sportsBank",
        meta: {
          title: "运动银行",
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegratedData" */
            "@/views/sportsBank/index.vue"
          ),
      },
      {
        path: "sportCode",
        name: "sportCode",
        meta: {
          title: "百姓运动码",
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegratedData" */
            "@/views/sportCode/index.vue"
          ),
      },
      {
        path: "fitnessSite",
        name: "fitnessSite",
        meta: {
          title: "百姓健身房",
        },
        component: () =>
          import(
            /* webpackChunkName: "IntegratedData" */
            "@/views/fitnessSite/index.vue"
          ),
      },
    ],
  },
];
const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  //   console.log("_to", to);
  //   console.log("_from", from);
  try {
    // console.log("_toNmae", to.name, to.meta.title, "https://dmtg.wenzhou.gov.cn:8068/web/mgop/gov-open/zj/2002307024/reserved/index.html#"+to.fullPath);
    aplus_queue.push({
      action: "aplus.sendPV",
      arguments: [
        {
          is_auto: false,
        },
        {
          // 当前你的应用信息，此两行请勿修改
          sapp_id: "35584",
          sapp_name: "BXYDM",
          // 自定义PV参数key-value键值对（只能是这种平铺的json，不能做多层嵌套），如：
          page_id: to.name, //	页面ID，与page 参数配合使用，保证唯一性必须要入参
          page_name: to.meta?.title,
          page_url: "https://dmtg.wenzhou.gov.cn:8068/web/mgop/gov-open/zj/2002307024/reserved/index.html#"+to.fullPath,
        },
      ],
    });

    // 设置会员ID
    aplus_queue.push({
      action: "aplus.setMetaInfo",
      arguments: ["_user_id", "79400694"],
    });

  } catch (error) {
    console.log("_aplus_queue.push.sendPV error", error);
  }

  next();
});

export default router;
